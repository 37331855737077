/* eslint-disable filenames/match-regex */
const sendMessageToParent = (token) => {
  window.parent.postMessage({ token }, '*')
}

export default (error) => {
  const messageToSend = error?.response?.data?.error?.token

  if (messageToSend) {
    sendMessageToParent(messageToSend)
  }
}
