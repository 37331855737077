import React from 'react'
import Backdrop from '@material-ui/core/Backdrop'
import {
  alpha, makeStyles,
} from '@material-ui/core'
import { getFormTheme } from './Loader.css'

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: 999,
    position: (({ position = 'absolute' }) => position),
    backgroundColor: ({ backgroundOpacity = 0.7 }) => alpha(
      theme.palette.background.default,
      backgroundOpacity,
    ),
    maxHeight: '100vh',
  },

  customLoader: {
    appearance: ' none',
    boxSizing: 'border-box',
    border: 'none',
    borderRadius: '50%',
    padding: theme.spacing(0.5),
    width: theme.spacing(6),
    height: theme.spacing(6),
    // need to investigate theme bug and remove getFormTheme
    color: getFormTheme().palette.primary.main,
    backgroundColor: 'transparent',
    fontSize: theme.typography.fontSize,
    overflow: 'hidden',
    '&::-webkit-progress-bar': {
      backgroundColor: 'transparent',
    },
    '&:indeterminate': {
      maskImage: 'linear-gradient(transparent 50%, black 50%), linear-gradient(to right, transparent 50%, black 50%)',
      animation: '$customLoader 6s infinite cubic-bezier(0.3, 0.6, 1, 1)',
      '&::before': {
        content: '""',
        display: 'block',
        boxSizing: 'border-box',
        marginBottom: theme.spacing(0.5),
        border: `solid ${theme.spacing(0.5)}px transparent`,
        borderTopColor: 'currentColor',
        borderRadius: '50%',
        width: '100% !important',
        height: '100%',
        backgroundColor: 'transparent',
        animation: '$customLoaderPseudo 0.75s infinite linear alternate',
      },
      '&::-ms-fill': {
        animationName: '-ms-ring',
      },
    },
  },
  '@keyframes customLoader': {
    '0%': {
      transform: 'rotate(0deg)',
    },
    '12.5%': {
      transform: 'rotate(180deg)',
      animationTimingFunction: 'linear',
    },
    '25%': {
      transform: 'rotate(630deg)',
    },
    '37.5%': {
      transform: 'rotate(810deg)',
      animationTimingFunction: 'linear',
    },
    '50%': {
      transform: 'rotate(1260deg)',
    },
    '62.5%': {
      transform: 'rotate(1440deg)',
      animationTimingFunction: 'linear',
    },
    '75%': {
      transform: 'rotate(1890deg)',
    },
    '87.5%': {
      transform: 'rotate(2070deg)',
      animationTimingFunction: 'linear',
    },
    '100%': {
      transform: 'rotate(2520deg)',
    },
  },
  '@keyframes customLoaderPseudo': {
    '0%': {
      transform: 'rotate(-30deg)',
    },
    '29.4%': {
      borderLeftColor: 'transparent',
    },
    '29.41%': {
      borderLeftColor: 'currentColor',
    },
    '64.7%': {
      borderBottomColor: 'transparent',
    },
    '64.71%': {
      borderBottomColor: 'currentColor',
    },
    '100%': {
      borderLeftColor: 'currentColor',
      borderBottomColor: 'currentColor',
      transform: 'rotate(225deg)',
    },
  },
}))

function Loader() {
  const classes = useStyles()

  return (
    <Backdrop open className={classes.backdrop}>
      <progress className={classes.customLoader} />
    </Backdrop>

  )
}

export default Loader
