import React, { useEffect } from 'react'
import {
  ORIGIN_URL, PAYMENT_API_URL,
} from '../../../consts'
import useStyles from './Iframe.css'

const Iframe = ({ hostUrl = '' }) => {
  const classes = useStyles()

  const handleIframeMessage = (event) => {
    const dataExists = !!event?.data
    const acceptableOrigin = event.origin === ORIGIN_URL || event.origin === PAYMENT_API_URL

    if (!dataExists || !acceptableOrigin) {
      return
    }

    const eventType = event.data[0]
    const variables = event.data[1]

    if (eventType === 'close-iframe') {
      window.parent.postMessage({
        type: 'close-iframe',
        variables,
      }, '*')
    }
  }

  useEffect(() => {
    window.addEventListener('message', handleIframeMessage, false)
    return () => window.removeEventListener('message', handleIframeMessage)
  }, [])

  return (
    <div className={classes.iframeContainer}>
      <iframe
        className={classes.iframe}
        src={hostUrl}
        srcDoc={hostUrl}
        title="iframe"
      />
    </div>
  )
}

export default Iframe
