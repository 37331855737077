/* eslint-disable filenames/match-regex */
import { makeStyles } from '@material-ui/core/styles'

export default makeStyles({
  dialog: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
  },
  button: {
    fontWeight: 'bold',
  },
  iframeContainer: {
    display: 'flex',
    height: '100%',
    width: '100%',
  },
  iframe: {
    border: 0,
    height: '100%',
    width: '100%',
  },
})
