import React, {
  useEffect, useState,
} from 'react'
import useStyles from './app.css'
import Loader from './components/Loader/Loader'
import PaymentProviderComp from './components/PaymentProviderComp'
import api from './lib/api'

const App = () => {
  const classes = useStyles()
  const [isInitialized, setIsInitialized] = useState(false)
  const [paymentProviderInfo, setPaymentProviderInfo] = useState()

  useEffect(() => {
    const initialize = async () => {
      const queryPath = window.location.search
      const paymentToken = new URLSearchParams(queryPath).get('paymentData')

      if (!paymentToken) {
        return null
      }
      const data = await api.initialize(paymentToken)

      if (data) {
        setIsInitialized(true)
        setPaymentProviderInfo(data)
      }
    }
    initialize()
  }, [])

  return (
    <div className={classes.root}>
      {
        isInitialized
          ? <PaymentProviderComp {...paymentProviderInfo} />
          : <Loader />
      }
    </div>
  )
}

export default App
