import { PAYMENT_TYPES } from '../../consts'
import Iframe from './Iframe/Iframe'

const components = {
  stripe: null,
}

const PaymentProviders = {
  [PAYMENT_TYPES.iframe]: Iframe,
  [PAYMENT_TYPES.component]: (componentName) => components[componentName.toLowerCase()],
}

export default PaymentProviders
