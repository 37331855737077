const {
  REACT_APP_PAYMENT_API_URL: PAYMENT_API_URL,
  REACT_APP_ORIGIN_URL: ORIGIN_URL,
} = process.env

const PAYMENT_TYPES = {
  iframe: 'IFRAME',
  component: 'COMPONENT',
}

export {
  PAYMENT_API_URL,
  ORIGIN_URL,
  PAYMENT_TYPES,
}
