/* eslint-disable filenames/match-regex */
import { createTheme } from '@material-ui/core/styles'

const DEFAULT_THEME = {
  palette: {
    primary: {
      main: '#00376c',
      light: '#45a4ff',
    },
    studioBuilder: {
      primary: {
        main: '#3e63ff',
        additional: '#3793ee',
        background: '#ffffff',
        light: '#d7e0fd',
        dark: '#1731a0',
      },
      secondary: {
        main: '#f25924',
        background: '#ffffff',
        light: '#ff8b52',
      },
      default: {
        main: '#959595',
        background: '#000000',
        light: '#f5f5f5',
      },
      text: {
        dark: '#000000',
        light: '#ffffff',
      },
      extras: {
        confirmBackground: '#4caf50',
        settingsContainersBackground: '#e3e8eb',
        sectionOverlay: '#71d7f7',
      },
    },
  },
}

const customCssFileRef = document.createElement('style')
customCssFileRef.setAttribute('data-meta', 'custom-css-global')
document.head.appendChild(customCssFileRef)

const customCssStyleRef = document.createElement('style')
customCssStyleRef.setAttribute('data-meta', 'custom-css-global')
document.head.appendChild(customCssStyleRef)

const theme = createTheme(DEFAULT_THEME)
const getFormTheme = () => theme

export {
  // eslint-disable-next-line import/prefer-default-export
  getFormTheme,
}
