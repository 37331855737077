/* eslint-disable filenames/match-regex */
import { makeStyles } from '@material-ui/core/styles'

export default makeStyles({
  root: {
    height: '100vh',
    width: '100vw',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
})
