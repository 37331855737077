import axios from 'axios'
import handleError from './handleError'
import { PAYMENT_API_URL } from '../consts'

const request = async (path, options) => {
  try {
    const {
      data: {
        data,
        success,
      } = {},
      status: statusCode,
    } = await axios({
      url: `${PAYMENT_API_URL}${path}`,
      ...options,
    })

    if (statusCode !== 200 || !success) {
      throw new Error('The request has failed')
    }
    return data
  } catch (err) {
    handleError(err)
  }
}

export default request
