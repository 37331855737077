import React from 'react'
import PaymentProviders from './Payments'

const PaymentProviderComp = ({
  type, ...props
}) => {
  const Payment = PaymentProviders[type]
  const { settings } = props

  if (!Payment) {
    return null
  }

  return <Payment {...settings} />
}

export default PaymentProviderComp
