import request from './request'

const api = {
  initialize: (paymentToken) => {
    const headers = {
      Authorization: `Bearer ${paymentToken}`,
    }
    return request('/payment', {
      method: 'POST',
      headers,
    })
  },
}
export default api
